import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from 'react-router-dom';

interface PasswordProtectionProps {
  onAuthenticated: () => void;
}

const PasswordProtection: React.FC<PasswordProtectionProps> = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [attempts, setAttempts] = useState(0);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const lastAuth = localStorage.getItem('lastAuthentication');
    if (lastAuth) {
      const lastAuthDate = new Date(lastAuth);
      const fiveDaysAgo = new Date();
      fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
      
      if (lastAuthDate > fiveDaysAgo) {
        onAuthenticated();
        setIsOpen(false);
      }
    }
  }, [onAuthenticated]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === 'V1P') {
      localStorage.setItem('lastAuthentication', new Date().toISOString());
      onAuthenticated();
      setIsOpen(false);
      toast({
        title: "Autenticado com sucesso",
        description: "Bem-vindo ao sistema!",
      });
    } else {
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      
      if (newAttempts >= 3) {
        toast({
          title: "Limite de tentativas excedido",
          description: "Você será redirecionado para a página inicial.",
          variant: "destructive",
        });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        toast({
          title: "Senha incorreta",
          description: `Tentativa ${newAttempts} de 3`,
          variant: "destructive",
        });
      }
    }
    setPassword('');
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      toast({
        title: "Autenticação cancelada",
        description: "Redirecionando para a página inicial.",
      });
      navigate('/');
    }
    setIsOpen(open);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Autenticação Necessária</DialogTitle>
          <DialogDescription>
            Por favor, insira a senha para acessar o sistema.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="password"
            placeholder="Digite a senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full"
          />
          <Button type="submit" className="w-full">
            Entrar
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordProtection;