import React, { Suspense, useState, useEffect } from 'react';
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PasswordProtection from "./components/PasswordProtection";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 30,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

// Lazy load components
const Navigation = React.lazy(() => import("./components/Navigation"));
const Index = React.lazy(() => import("./pages/Index"));
const Pix = React.lazy(() => import("./pages/Pix"));
const Cnpj = React.lazy(() => import("./pages/Cnpj"));
const Banks = React.lazy(() => import("./pages/Banks"));
const Cpf = React.lazy(() => import("./pages/Cpf"));
const Phone = React.lazy(() => import("./pages/Phone"));

const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-[50vh]" role="status" aria-label="Carregando...">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
  </div>
);

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = React.useMemo(() => {
    const lastAuth = localStorage.getItem('lastAuthentication');
    if (lastAuth) {
      const lastAuthDate = new Date(lastAuth);
      const fiveDaysAgo = new Date();
      fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
      return lastAuthDate > fiveDaysAgo;
    }
    return false;
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-background">
        <PasswordProtection onAuthenticated={() => window.location.reload()} />
      </div>
    );
  }

  return children;
};

const AppContent = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isAuthenticated = React.useMemo(() => {
    const lastAuth = localStorage.getItem('lastAuthentication');
    if (lastAuth) {
      const lastAuthDate = new Date(lastAuth);
      const fiveDaysAgo = new Date();
      fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
      return lastAuthDate > fiveDaysAgo;
    }
    return false;
  }, []);

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.classList.toggle('sidebar-expanded', !sidebarCollapsed);
    }
  }, [sidebarCollapsed]);

  return (
    <div className="min-h-screen bg-background">
      <Toaster />
      <Sonner />
      <Suspense fallback={<LoadingFallback />}>
        {!isHomePage && isAuthenticated && <Navigation onCollapse={setSidebarCollapsed} />}
      </Suspense>
      <main 
        id="main-content" 
        className={`transition-all duration-300 ${
          !isHomePage && isAuthenticated
            ? `p-4 md:p-6 lg:p-8 ${
                sidebarCollapsed 
                  ? 'md:ml-16' 
                  : 'md:ml-64'
              }`
            : 'p-4'
        }`}
        aria-label="Main content"
      >
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/pix" element={<ProtectedRoute><Pix /></ProtectedRoute>} />
            <Route path="/cnpj" element={<ProtectedRoute><Cnpj /></ProtectedRoute>} />
            <Route path="/banks" element={<ProtectedRoute><Banks /></ProtectedRoute>} />
            <Route path="/cpf" element={<ProtectedRoute><Cpf /></ProtectedRoute>} />
            <Route path="/phone" element={<ProtectedRoute><Phone /></ProtectedRoute>} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;